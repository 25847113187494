import React from "react";
import { Box } from "../../../../elements/Box";
import { BsFillCaretRightFill } from "react-icons/bs";

const Arrow = ({ color = "teal", left, right, rotate, className, onClick, size = 50 }) => {
  let margin = "0";
  if (left) {
    rotate = 180;
    margin = "auto 0 auto 1rem";
  }
  if (right) {
    rotate = 0;
    margin = "auto 1rem auto 0";
  }

  return (
    <Box
      margin={margin}
      display="flex"
      s
      flexDirection="flex"
      alignItems="center"
      justifyContent="center"
      transform={`rotate(${rotate}deg)`}
      onClick={onClick}
      className={className}
      width={`${size}px`}
      height={`${size}px`}
      left={left ? 0 : "unset"}
      right={right ? 0 : "unset"}
      top={0}
      bottom={0}
      zIndex={20}
    >
      <BsFillCaretRightFill size={size} color={color} />
    </Box>
  );
};

Arrow.propTypes = {};

export default Arrow;
